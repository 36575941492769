.product {
  /***
   * Product
   * Categories
   */
  &-category {
    position: relative;
    overflow: hidden;

    @media #{$tablet-min} {
      padding-bottom: 23%;
    }

    .taxonomy-term-description {
      color: $mine-shaft;
      @include middle-content;

      @media #{$tablet-min} {
        padding: 10px;
      }

      @media #{$wide-min} {
        padding: 30px;
      }
    }

    .group-wrapper > div {
      font-size: 15px;
      line-height: 20px;

      @media #{$tablet-min} {
        width: 33.33%;
        float: left;
      }

      @media #{$wide-min} {
        font-size: $font-size-md;
        line-height: 26px;
      }
    }

    .field-name-field-product-image {
      margin-bottom: 30px;

      @media #{$tablet-min} {
        margin-bottom: 0;
      }

      > img {
        @media #{$tablet-max} {
          margin-bottom: 10px;
        }

        &:nth-child(n+2) {
          @media #{$tablet-min} {
            position: absolute;
            bottom: 0;
            width: 33.1%;
          }
        }
        &:nth-child(2) {
          @media #{$tablet-min} {
            left: 0;
          }
        }
        &:nth-child(3) {
          @media #{$tablet-min} {
            width: 66.5%;
            right: 0;
          }
        }
      }
    }


    &:nth-child(n+4) {
      @media #{$tablet-min} {
        padding-bottom: 0;
      }
      .field-name-field-product-image > img:nth-child(2) {
        @media #{$tablet-min} {
          width: 66.5%;
        }
      }
    }

    &:nth-child(n+2) {
      @media #{$tablet-max} {
        padding-top: 30px;
        border-top: 1px solid $barley-corn;
      }
    }

    &:nth-child(n+5) {
      .field-name-field-product-image > img {
        &:nth-child(2) {
          @media #{$tablet-min} {
            width: 33.3%;
          }
        }

        &:nth-child(3) {
          @media #{$tablet-min} {
            width: 33.3%;
            left: 33.5%;
            right: auto;
          }
        }
      }
    }

    &:last-child {
      .group-wrapper {
        > .field-name-field-image {
          @media #{$tablet-min} {
            width: 66.3%;
          }
        }
        > .taxonomy-term-description {
          @media #{$tablet-min} {
            width: 33.7%;
          }
        }
        > .field-name-field-product-image {
          width: 100%;
          img {
            @media #{$tablet-min} {
              position: static;
              float: left;
            }

            &:nth-child(1) {
              @media #{$tablet-min} {
                width: 66.3%;
              }
            }
            &:nth-child(2) {
              @media #{$tablet-min} {
                width: 33.3%;
                float: right;
              }
            }
          }
        }
      }
    }
  }

  /***
   * Product
   * Information
   */
  &-information > div {
    @media #{$tablet-min} {
      padding: 60px 60px 90px;
    }
  }

  /***
   * Product
   * Themes
   */
  &-themes {
    font-size: $font-size-md + 4;
    line-height: 34px;
    margin-bottom: 3px;
    .text-lg {
      font-size: $font-size-lg + 2;
      margin-top: 25px;
    }
    .group-left {
      @media #{$tablet-min} {
        text-align: right;
      }
    }
    &:nth-child(2n+2) .group-left {
      @media #{$tablet-min} {
        float: right;
        text-align: left;
      }
    }
  }
}
