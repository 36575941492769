$clear-shadow: 0 0 transparent;

// Solid colors
$white: #fff;
$black: #000;

// Shades of color grey
$mine-shaft: #333;
$swirl     : #d4cfc5;
$thunder   : #201e1f;

// Shades of color brown
$barley-corn: #9b835d;
$mongoose   : #af9c7c;
$nomad      : #b8Af9E;
$straw      : #d9bc90;

// Judd
$bg-brown   : #c9b092;
$brown-lighter : #ebd5be;
$brown-darker  : #6b4c2f;
$peach: #f8efe8;

// Shades of color yellow
$solitaire: #fef5e6;
$beeswax  : #fde7C0;
$parchment: #f1e4d3;

// Shades of color orange
$brandy: #dbbc91;

// Shades of color blue
$nepal     : #879bbf;
$botticelli: #cfd7e5;
$mystic    : #e7ebf2;
$manatee   : #8e98ab;

// Shades of color green
$bandicoot : #79796a;
$bison-hide: #c1b9aa;