.group {
  // Group left
  &-left {

    // Page banner region
    .page-banner & {
      margin-left: 15px;
      margin-right: 15px;

      @media #{$tablet-min} {
        width: 39.3%;
        margin: 0 2% 0 0;
      }
    }

    // Attachment
    .attachment & {
      padding: 0;
      @media #{$tablet-min} {
        width: 52.2%;
        float: left;
      }
    }

  }

  // Group right
  &-right {

    // Page banner region
    .page-banner & {
      @media #{$tablet-min} {
        width: 58.7%;
      }
      @media #{$normal-min} {
        margin-top: -21.3%;
      }
    }

    // Footer region
    .footer & {
      padding: 0 15px;
    }

    // Attachment
    .attachment & {
      padding: 0;
      @media #{$tablet-min} {
        width: 47.8%;
      }
    }

    // Webform
    .node-webform & {
      @media #{$normal-max} {
        padding: 0;
      }
    }

  }

  // Group footer
  &-footer {
    clear: both;
  }
}
