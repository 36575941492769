/***
 * Front Page
 */

.front {
  .main-container {
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    > div > section {
      padding: 0;
    }
    .bl-wrapper {
      max-width: $container;
      margin: auto;
    }
  }
}

/*.bl-categories {
  padding-top: 80px;
  padding-bottom: 90px;
  @include bg-divider();
}*/

.bl-highlight {
  @include bg-divider($white);

  h2 {
    max-width: 275px;
    @media #{$wide-min} {
      font-size: $font-size-xlg - 5;
    }
  }
  .bl-wrapper {
    padding: 30px;

    @media #{$tablet-min} {
      padding: 60px 15px 23px;
      background: url($bg-accent-url) no-repeat right 35px;
    }

    @media #{$wide-min} {
      padding-left: 90px;
      padding-right: 90px;
    }
  }

  .group-left {
    @media #{$wide-min} {
      width: 32.4%;
    }
  }
  .group-right {
    @media #{$wide-min} {
      width: 67.6%;
    }
  }
}

.bl-highlight {
  &.where-find-section .bl-wrapper {
    padding-bottom: 0;
  }
  &.video-section {
    background: none;
    background-color: #FFF;

    h2 {
      max-width: none;
    }
    .bl-wrapper {
      @media #{$tablet-min} {
        background-position: left 7px;
      }
    }
  }
}
