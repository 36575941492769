/***
 * Footer
 */

.footer {
  font-size: $font-size-footer;
  margin-top: 0;
  padding-bottom: 10px;
  background-color: $mystic;

  // Footer blocks
  .block {
    margin-bottom: 20px;
    @media #{$tablet-max} {
      border-bottom: 1px dashed darken($mystic, 10%);
      padding: 10px 20px 13px 20px;
    }

    // Footer last block span
    &:last-child span {
      line-height: 14px;
      display: block;
    }

    // Footer block title
    &-title {
      font-family: $font-itc-bold;
      font-size: $font-size-block-title-footer;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  // Footer navigation
  .menu {
    .nolink,
    .home,
    .icon {
      display: none;
    }
    li a {
      padding: 0;
      line-height: $text-normal;
      margin-bottom: 7px;
      #{$state-hover} {
        color: $manatee;
        background-color: transparent;
      }
    }
    .ionicon {
      line-height: 40px;
      margin-right: 9px;
      #{$state-hover} {
        background-color: darken($mine-shaft, 6.5);
        color: $white;
      }
    }
  }
  img {
    margin: 0;
  }
}