@mixin standard-transition($name: all, $duration: 300ms, $timing: ease, $delay: 0s) {
  -webkit-transition: $name $duration $timing $delay;
          transition: $name $duration $timing $delay;
}

/*@mixin center-block() {
  margin-left: auto;
  margin-right: auto;
}*/

@mixin clearfix {
  #{$psuedo-before},
  #{$psuedo-after} {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin middle-block {
  display: inline-block;
  vertical-align: middle;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin position($position: absolute, $top: null, $right: null, $left: null, $bottom: null, $z-index: 20, $height: null, $min-height: null, $width: null, $max-width: null) {
  position: $position;
  top: $top;
  right: $right;
  left: $left;
  bottom: $bottom;
  z-index: $z-index;
  height: $height;
  min-height: $min-height;
  max-width: $max-width;
  width: $width;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin bg-color-bar($bg-color, $bg-spacing: null) {
  background-color: $bg-color;
  padding: $bg-spacing;
}

@mixin bg-divider($bg-color: null, $bg-position: center 0) {
  background: url($bg-divider-data) repeat-x $bg-position $bg-color;
}

@mixin bg-divider-shorter {
  background: url($bg-divider-shorter-data) no-repeat center 0;
}

@mixin background-color-transparent($color, $opacity) {
  background-color: $color;
  background-color: rgba($color, $opacity);
}

@mixin css-property($property, $value, $vendor-prefixes: ()) {
  @each $prefix in $vendor-prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }

  // Output standard non-prefixed declaration
  #{$property}: $value;
}

@mixin middle-content {
  height: 100%;
  display: flex;
  @include css-property(justify-content, center, ('webkit', 'moz'));
  @include css-property(flex-direction, column, ('webkit', 'moz'));
}
