.toboggan-unified {
  /***
   * Login
   * Form
   */
  form {
    max-width: 1000px;
    margin: 20px auto 0;
    position: relative;
  }

  #login {
    /***
     * Login
     * Message
     */
    &-message {
      font-size: $font-size-login-message;
      margin-bottom: 30px;
    }

    /***
     * Login
     * Links
     */
    &-links a {
      font-size: $font-size-sm;
      border: 2px solid $straw;
      background-color: $solitaire;
      padding: 0 20px;
      display: inline-block;
      margin-bottom: 20px;

      &.lt-active,
      &.lt-active:hover,
      &:hover {
        background-color: $straw;
        border-color: $straw;
        color: $white;
      }
    }
  }

  .login-forgot {
    @include position($right: 0, $bottom: 0);
  }
}

// Login form
.form {
  /***
   * Password
   * Form
   */
  &-type-password-confirm .help-block {
    position: static;
    border: 0;
    color: $mine-shaft;
  }

  &-item-pass .form-item {
    @media #{$tablet-min} {
      clear: none;
      width: 50%;
    }
  }
}

.has-feedback label ~ .form-control-feedback {
  top: 33px;
}

.user-info-from-cookie {
  /***
   * Account
   * Terms
   */
  .field-type-list-boolean {
    border-top: 1px solid $straw;
    border-bottom: 1px solid $straw;
    text-align: center;
    clear: both;
    label {
      font-size: $font-size-global;
      margin: 10px 0;
      a {
        color: $barley-corn;
      }
    }
  }

  /***
   * Account
   * Location
   */
  .field-type-location {
    margin-top: 30px;
    .panel-body {
      margin: 0 #{-$standard-gutter};
      > div {
        clear: none;
        padding: 0 $standard-gutter;
        @media #{$tablet-min} {
          float: left;
          width: 50%;
        }
        &:last-child {
          width: 100%;
          margin-bottom: $standard-gutter;
        }
      }
    }
  }

  // Form submit wrapper
  .form-actions {
    text-align: center;
  }
}

/***
 * Panel
 */
.panel {
  box-shadow: $clear-shadow;
  border-radius: 0 0;
  background: transparent;
  border: 0;
  // Panel title.
  > .panel-heading {
    text-align: center;
    text-transform: uppercase;
    background: transparent;
    border-bottom: 3px solid $straw;
    border-radius: 0 0;
    margin-bottom: 20px;
    padding: 0 0 5px;
  }

  // Panel title.
  &-title {
    font-size: $font-size-sm;
  }

  // Panel body.
  &-body {
    padding: 0;
  }
}

.group {
  /***
   * Group
   * Account
   */
  &-account .form-wrapper {
    margin: 0 #{-$standard-gutter};
    > div {
      padding: 0 $standard-gutter;

      &:not(:last-child) {
        @media #{$tablet-min} {
          float: left;
          width: 50%;
        }
      }

      &:last-child {
        clear: both;
      }
    }
  }

  /***
   * Group
   * Business
   */
  &-business > div {
    margin: 0 #{-$standard-gutter};

     > div {
      padding: 0 $standard-gutter;

      @media #{$tablet-min} {
        float: left;
        width: 50%;
      }
    }

    > .field-type-image {
      width: 100%;
      margin-top: 20px;
    }

    .grippie {
      display: none;
    }
  }
}

/***
 * Location
 */

.location .form-item {
  label {
    float: none;
  }
  input {
    width: 100%;
    margin: 0;
  }
}

.adr {
  float: left;
  margin-right: 5px;
}