/***
 * Container
 */

.container {
  width: 100%;
  max-width: $container;
  min-width: 320px;

  // Page banner
  .page-banner & {
    max-width: 1600px;
    @media #{$tablet-min} {
      padding-left: 30px;
    }
  }

}
.main-container {
  padding-top: 40px;
  padding-bottom: 40px;
}