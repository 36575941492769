/*
 * Global classes
 */

.d-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.j-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.a-items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.f-direct-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.f-direct-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.f-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.f-grow {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-view-content .view-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.where-find-section {
  padding-bottom: 50px;
}
.bg-pattern-white {
  background-image: url($bg-pattern-white);
}
.bg-pattern-flesh {
  background-image: url($bg-pattern-flesh);
}
.bg-pattern-flesh-after,
.bg-pattern-white-after {
  position: relative;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    visibility: visible;
  }
}
.bg-pattern-white-after::after {
  background-image: url($bg-pattern-white);
}
.bg-pattern-flesh-after::after {
  background-image: url($bg-pattern-flesh);
}
.cover-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.z-top { z-index: 3; }

// colors
.bg-white {
  background-color: #FFF;
}
.bg-brown {
  background-color: $bg-brown;
}
.bg-brown-lighter {
  background-color: $brown-lighter;
}
.bg-brown-darker {
  background-color: $brown-darker;
}
.bg-peach {
  background-color: $peach;
}
.text-brown {
  color: $brown-darker;
}
.text-white {
  color: #FFF;
}

// fonts
.font-boldpitch { font-family: $font-relative-bold }
.font-10pitch { font-family: $font-relative-10; }
.font-12pitch { font-family: $font-relative-12; }

/*
 * Taxonomy pages
 */
.taxonomy-type-msme,
.node-type-msme-product {
  .page-header {
    display: none;
  }
}


// product
.node-msme-product {
  line-height: 1;
  font-size: 14px;
}

// Partners page
.page-our-partners .img-bordered {
  max-width: 287px;
}

// slider front page
.slides .field-name-find-out-more {
  position: absolute;
  top: 55%;
  left: 45.2%;
}
.slides .field-name-find-out-more .btn {
  background-color: transparent;
  border: 2px solid #333;
}
.flexslider li {
  position: relative;
}

/*
 * Footer
 */
.taxonomy-type-msme footer .ionicon,
.node-type-msme-product footer .ionicon {
  color: #FFF;
}

// Block language switcher
.language-switcher-locale-url {
  list-style: none;
  text-align: right;
  margin: 0;
  padding: 15px;
  padding-right: 4.5%;
  padding-bottom: 5px;

  @media #{$tablet-max} {
    padding-right: 0;
  }

  li {
    display: inline-block;
    margin-left: 10px;

    .taxonomy-type-msme &,
    .page-srcl &, .node-type-msme-product & {
      margin-left: 10px;
      @media #{$tablet-max} {
        margin-left: 5px;
      }
    }
  }
  a {
    font-size: 14px;
  }
}
.taxonomy-type-msme:not(.page-srcl),
.node-type-msme-product {
  .block-locale-language {
    position: absolute;
    right: 2.5%;
    top:0;
    width: 135px;
  }
  .language-switcher-locale-url {
    display: inline-block;
    li {
      float: left;
    }
  }
}

.taxonomy-type-msme .navbar.elShow,
.node-type-msme-product .navbar.elShow {
  .block-locale-language {
    @media #{$tablet-max} {
      display: none;
    }
  }
  .dl-menuwrapper {
    @media #{$tablet-max} {
      margin-top: 10px;
    }
  }
}

.supplier-videos-block {
  clear: both;
  margin-top: 50px;
  padding-top: 50px;
}

