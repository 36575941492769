/***
 * Table
 */

table {
  border: 0;
  margin: 0 auto 20px;

  tbody {

    td {
      border: 0;
      width: 50%;
      padding: 0 0 20px;

      @media #{$tablet-max} {
        width: 100% !important;
        display: block;
      }

    }

  }

}
