.scroll-down {
  z-index: 3;
  width: 100%;
  font-size: 32px;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  color: darken($brown-darker, 30%);

  @media #{$tablet-min} {
    position: absolute;
    bottom: 0;
    padding-bottom: 50px;
    background-color: transparent !important;
  }
  i:hover {
    cursor: pointer;
  }
}
.page-srcl {
  .main-container-wrap {
    &::after { bottom: 0; }
    .container {
      @media #{$tablet-max} {
        padding: 40px 8%;
      }
    }
  }
  h2.font-boldpitch {
    @media #{$tablet-max} {
      font-size: 20px;
    }
  }
}

.new-design-block {
  .view-content > div {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    @media #{$tablet-min} {
      height: 100vh;
      min-height: 735px;
    }
    @media #{$tablet-max} {
      background-color: $bg-brown;
      .top { order: 2; }
      .bottom {
        order: 1;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        > div:first-of-type { order: 1 }
      }
    }
  }
  .top {
    > div {
      padding-bottom: 6.5%;
      &:nth-child(1) {
        padding-right: 5%;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;

        @media #{$tablet-max} {
          display: none;
        }
      }
      &:nth-child(2) {
        font-size: $font-size-page-header + 3;
        letter-spacing: 3px;
        line-height: 1.3;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
         align-items: flex-end;
        padding-left: 5%;
        text-transform: uppercase;

        @media #{$wide-max} {
          font-size: 38px;
        }
        @media #{$tablet-max} {
          width: 100%;
          font-size: 28px;
          padding: 10%;
        }
        @media #{$phone-landscape-max} {
          font-size: 25px;
        }
      }
    }
    .body {
      max-width: 300px;

      @media #{$wide-max} {
        max-width: 250px;
      }
    }
  }
  .bottom {
    div:first-of-type {
      height: 225px;
      background-position: -28px -14px;
      @media #{$tablet-max} {
        height: 110px;
        background-size: 35px;
      }
    }
  }
}
body.page-srcl {
  padding-top: 0 !important;

  .elShow, .elHide {
    background: $bg-brown !important;
  }
  .elShow {
    border-bottom: 2px solid darken($bg-brown, 25%);
    @media #{$tablet-max} {
      img {
        max-width: 100px;
      }
    }
  }
  .navbar-header {
    display: none;

    @media #{$tablet-max} {
      display: block;
      padding: 5px;
    }
    @media #{$phone-landscape-max} {
      img { width: 100%; }
    }
  }
  .navbar-right {
    @media #{$tablet-min} {
       width: 100%;
       padding-top: 5px;
       padding-bottom: 23px;
    }
  }
  .navbar-default .navbar-right .menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.view-msme-term {
  z-index: 2;
  position: relative;
  .view-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .form-radios {
    text-transform: lowercase;
    margin-bottom: 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .form-control, .form-control.form-select {
    font-size: $font-size-global;
    height: 40px;
    padding: 0px 8px;
    border: 1px solid $bg-brown;
  }
  .views-exposed-form {
    margin-left: -10px;
    margin-right: -10px;

    .views-widget-filter-name_1 {
      width: 100%;
    }
    @media #{$normal-max} {
      .views-widget { margin-top: 10px; }
      .form-radios {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: end;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 25px 0;

        > div { width: 10%; }
      }
    }
  }
  .inline-items > div.radio:nth-child(n+2) {
    border: none;
    &::before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 3px;
      border-radius: 100%;
      background: $brown-darker;
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);

      @media #{$normal-max} {
        left: 0;
      }
    }
  }
  .msme-item {
    line-height: 1.2;

    img { margin-bottom: 10px; }
  }
  @media #{$wide-min} {
    .msme-item {
      padding: 0 40px;
      line-height: 1
    }
    &.view-msme-filter .view-content {
      margin-left: -40px;
      margin-right: -40px;
    }
  }
  @media #{$tablet-max} {
    padding: 0;

    .msme-item {
      padding: 0;

      img { width: 100%; }
    }
  }
}


// Fashion Home
.bot-msme h2 {
  padding-right: 75px;
}
.fashion-home-block {
  .view-content {
    @media #{$wide-min} {
      padding-top: 140px;
      padding-bottom: 110px;
    }
    @media #{$tablet-max} {
      padding: 12%;
    }
  }
  .fashion-home-item {
    @media #{$wide-min} {
      &:first-of-type {
        padding-right: 80px;
      }
      &:last-of-type {
        padding-left: 50px;
      }
    }
    @media #{$tablet-max} {

      line-height: 1.5;
      h2 { font-size: 25px; }
      &:first-of-type { margin-bottom: 60px; }
      &:last-of-type { text-align: right; }
    }
  }

  @media #{$wide-max} {
    .top { height: 125px !important; }
    .bottom h2 {
      font-size: 48px;
      padding-top: 35px;
      padding-right: 15%;
      padding-bottom: 35px;
    }
  }
  @media #{$tablet-max} {
    .top {
      height: 125px !important;
      > div:first-of-type {
        background-size: 35px;
        background-position: -7px -5px;
      }
    }
    .bottom {
      h2 {
        margin: 0;
        font-size: 30px;
        padding: 15% 10%;
      }
      .bg-pattern-flesh {
        background-size: 35px;
        background-position: -7px -7px;
      }
    }
  }
}

// Our Dream
.our-dream-item {
  > div:first-child {
    @media #{$wide-min} {
      padding-right: 150px;

      & .body {
        max-width: 465px;
        margin-left: auto;
      }
    }
  }
  @media #{$wide-max} {
    &.a-items-center {
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    > div:first-child { padding: 2%; }
  }
  @media #{$tablet-max} {
    .text-right { text-align: center; }
    > div:first-child {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      padding: 10%;
      line-height: 1.5;
    }
  }
}

// About Go Lokal
.about-golokal-block {
  img { margin: 0; }

  .bg-pattern-white-after::after {
    right: 0;
    bottom: 0;
    width: 30%;
    height: 235px;
    background-position: -6px -12px;
  }
  .our-dream-item {
    z-index: 3;

    &.bg-pattern-white-after:after {
      @media #{$tablet-max} {
        height: 110px;
        width: 52%;
        background-size: 35px;
      }
    }
    @media #{$wide-min} {
      padding-top: 140px;
      padding-bottom: 110px;
    }
    > div {
      @media #{$wide-max} {
        padding: 0 15px;

        img { margin: auto; }
      }
      @media #{$tablet-max} {
        width: 100%;
        text-align: center;
        padding: 0;
        &:first-of-type {
          z-index: 2;
          font-size: 16px;
          line-height: 1.5;
          padding: 6%;
          padding-bottom: calc(10% + 60px);
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
        }
      }
    }
  }
}

// Video | Our inspiration
.video-section {
  .view-content {
    h2 { line-height: 1.3 }
    @media #{$wide-min} {
      .video-content {
        z-index: 2;
        padding-left: 35%;
        padding-top: 222px;
      }
      .venobox {
        z-index: 2;
        margin-right: -108px;
        position: relative;
      }
    }
  }
  .attach {
    padding-left: 2%;

    @media #{$wide-min} {
      padding-left: 9%;
      padding-top: 120px;
      padding-bottom: 120px;
      > div {
        padding-right: 50%;
      }
    }
  }

  @media #{$tablet-max} {
    .video-content, .view-content.col-sm-6 {
       padding: 0;
    }
    .venobox, .venobox > img { width: 100%; }
    h2.text-xlg {
      font-size: 25px;
      padding: 7% 5%;
      margin: 0;
    }
    .attach {
      text-align: center;
      padding: 7%;
      line-height: 1.5;
    }
  }
}

// Retail Popup Block
.retail-pop-up {
  table { line-height: 1.4; }
  a {
    display: inline-block;
    margin-right: 16px;
  }
  .view-content > div:last-of-type {
    margin-bottom: 0;
  }
  @media #{$wide-min} {
    padding-top: 150px;
    padding-bottom: 130px;

    .view-content > div:not(:last-of-type) {
      margin-bottom: 180px;
    }
  }
  @media #{$tablet-max} {
    .container { padding: 0 8%; }
  }
}

// Contributors Block
.our-contirbutors {
  &.bg-pattern-flesh-after::after {
    top: 0; right: 0;
    width: 500px; height: 675px;
  }
  @media #{$wide-min} {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .view {
    z-index: 2;
    position: relative;
  }
}
.contributors-item {
  z-index: 2;
  strong {
    font-size: 18px;
    line-height: 1;
  }
  img {
    margin-left: 0;
    margin-bottom: 40px;
  }
  @media #{$wide-min} {
    &:first-of-type {
      padding-right: 7%;
    }
    &:last-of-type {
      padding-left: 7%;
    }
  }
  @media #{$phone-landscape-max} {
    width: 100%;
    margin-bottom: 50px;
  }
}

// Visit Go lokal stores
.visit-golokal {
  .view-content {
    line-height: 1.3;
    font-size: 16px;

    a { color: #FFF; }
  }
  @media #{$wide-min} {
    padding-top: 120px;
    padding-bottom: 90px;
    .view-content { font-size: 19px; }
    .container {
      padding-left: 100px;
    }
    td:last-of-type {
      padding-left: 80px;
    }
  }
  @media #{$tablet-max} {
    padding: 7%;
    h2 { font-size: 20px; }
    .container {
      padding: 0;
      width: 100%;
      display: inline;
    }
  }
}

// Acknowledgement
.page-srcl .main-container-wrap::after {
    width: 42%;
    height: 23%;
    background-position: 0 -15px;

    @media #{$wide-max} {
      background-size: 35px;
    }
    @media #{$tablet-max} {
      width: 60%;
      height: 10%;
      background-position: -18px -10px;
    }
}
.acknowledgement-item {
  &:first-of-type {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .supported {
      display: none;
    }
    @media #{$wide-min} {
      margin-bottom: 150px;
    }
  }
  &:nth-child(2) {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      margin: 0 30px;
    }
    .supported {
      width: 100%;
    }
  }
}

.acknowledgement-block {
  @media #{$wide-min} {
    .view-header > div {
      margin-bottom: 125px;
    }
  }
  @media #{$tablet-max} {
   .view { padding: 0 7%; }
   .img { max-width: 250px; }
  }
  @media #{$phone-landscape-max} {
    .acknowledgement-item:first-of-type {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      .img {
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }
  }
}

// MSME page
@import 'page-msme';