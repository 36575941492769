/***
 * Page Banner
 */

.page-banner {
  background: url($bg-banner-url) repeat-x 0 0;
  position: relative;
  padding-bottom: 40px;

  @media #{$tablet-min} {
    padding: 40px 0 110px;
  }

  #{$psuedo-before} {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fef5e6+0,fef5e6+100&0+1,1+66 */
    background: -moz-linear-gradient(top,  rgba(254,245,230,0) 0%, rgba(254,245,230,0) 1%, rgba(254,245,230,1) 66%, rgba(254,245,230,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(254,245,230,0) 0%,rgba(254,245,230,0) 1%,rgba(254,245,230,1) 66%,rgba(254,245,230,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(254,245,230,0) 0%,rgba(254,245,230,0) 1%,rgba(254,245,230,1) 66%,rgba(254,245,230,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fef5e6', endColorstr='#fef5e6',GradientType=0 ); /* IE6-9 */
    @include position(absolute, $bottom: 0, $height: 100px, $width: 100%);
  }

  // Heading 2
  h2 {
    font-size: $font-size-banner-h2;
    max-width: 400px;
    margin: auto;
  }

  // Logo section
  .logo {
    margin-bottom: 30px;
    @media #{$tablet-min} {
      width: 39.3%;
    }
    img {
      max-width: 200px;
      @media #{$normal-min} {
        max-width: 100%;
      }
    }
  }
}