/***
 * Header
 */

.navbar {
  &.container {
    border: 0;
    margin: 0;
    max-width: 100%;
    padding: 0;
    -webkit-border-radius: 0;
    border-radius: 0;

    @include position(absolute, $top: 0, $left: 0, $z-index: 500);
    @include bg-divider($solitaire, center bottom);

    .taxonomy-type-msme &,
    .node-type-msme-product & {
      background: $bg-brown;

      @media #{$tablet-max} {
        display: block;
        position: relative;
      }
      .logo { margin-bottom: 0; }
      &.elShow {
        border-bottom: 1px solid darken($bg-brown, 25%);
        @media #{$tablet-max} {
          img {
            max-width: 100px;
          }
        }
      }
    }
    .page-srcl & { background: transparent; }
    > .container {
      margin: auto;

      @media #{$tablet-max} {
        padding: 0;
      }
    }
    .admin-menu & {
      top: 29px;
    }
    .scrolled & {
      position: fixed;
    }
  }
  .logo {
    display: block;
    margin: 0;
    padding: 0;
    margin-bottom: #{-$standard-gutter};
    img {
      margin: 0;
      max-width: none;
      .page-srcl & {
        max-width: 200px;
      }
    }
  }

  // Navbar right
  &-right {
    .front & {
      margin: 0;
      width: 100%;
    }
  }

  // Header
  &-header {
    .container > & {
      margin: auto;
    }
    @media #{$tablet-max} {
      padding-left: 0;
    }
  }
}

// .admin-menu.scrolled {
  // top: 29px;
// }
