.webform {
  /***
   * Webform
   */
  &-client-form {
    margin: 0 #{-$standard-gutter};

    .form-actions {
      padding: 0 $standard-gutter;
    }
  }

  /***
   * Webform
   * Components
   */
  &-component {
    padding: 0 $standard-gutter;
    margin-bottom: 20px;

    // Component label.
    > label {
      display: none;
    }

    // Fieldset component.
    &-fieldset {
      box-shadow: $clear-shadow;
      border: 0;
      border-radius: 0 0;
      background: transparent;

      @media #{$tablet-min} {
        float: left;
        width: 50%;
      }
    }
  }

  /***
   * Webform
   * Confirmation links
   */
  &-confirmation + .links {
    text-align: center;
  }
}

.captcha {
  clear: both;
  padding: 0 $standard-gutter 20px;
}
