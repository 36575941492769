/***
 * Maintenance page
 */

@import 'vars-typography';

.in-maintenance {
  font: #{$font-size-global}/1.8 $font-itc-book;
  color: $black;
  margin: 0;
  overflow-y: scroll;
  padding: 0 20px;
  background: url("/sites/all/themes/bootstrap_golokal/misc/bg-banner.jpg") repeat-x 0 0 #fef5e6;
  min-width: 265px;

  img {
    max-width: 100%;
    height: auto;
  }
  // Headings
  h1 {
    font-size: $font-size-h2;
    margin: 0 0 10px;
  }

  // Paragraph
  p {
    margin: 0;
  }

  // Page container
  #page {
    max-width: 600px;
    margin: 50px auto;
    text-align: center;
  }

  //Logo
  #logo-title {
    margin-bottom: 30px;
  }

  // Site name
  #site-name {
    display: none;
  }

  // Content
  .column {
    padding: 5px;
    border: 1px solid $nomad;
    > div {
      padding: 45px;
      background-color: $parchment;
      border: 1px solid $nomad;
    }
  }
}
