@import 'layout-group';

/***
 * Patterns
 */

.bg-pattern {
  //padding-top: 40px;
  background: url($bg-banner-inner-url) repeat-x 0 0;
  .taxonomy-type-msme &,
  .node-type-msme-product & {
    background: none;
  }
}

.img {
  &-shadow img {
    @include css-property(box-shadow, 0 0 10px rgba($black, 0.4), ('webkit', 'moz'));
  }
  &-bordered img {
    border: 1px solid $nomad;
    background-color: $white;
    &[src*=".png"] {
      background-color: transparent;
      border: 0;
    }
  }
}

/***
 * Position
 */

.pos-item {
  position: absolute;
  &.top {
    top: 0;
  }
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
  &.bottom {
    bottom: 0;
  }
  &.middle {
    top: 43%;
  }
}

.full {
  &w {
    width: 100%;
  }
  &h {
    height: 100%;
  }
}

/***
 * Spacing
 */

.mb {
  &-xs {
    margin-bottom: $outer-spacing-xs;
  }
  &-sm {
    margin-bottom: $outer-spacing-sm;
  }
  &-md {
    margin-bottom: $outer-spacing-md;
  }
  &-lg {
    margin-bottom: $outer-spacing-sm;
    @media #{$tablet-min} {
      margin-bottom: $outer-spacing-lg;
    }
  }
  &-xl {
    margin-bottom: $outer-spacing-md;
    @media #{$tablet-min} {
      margin-bottom: $outer-spacing-xl;
    }
  }
}

.no {
  &-pd {
    padding: 0;
  }
  &-mg {
    margin: 0;
  }
}
.p {
  &b-xs {
    padding-bottom: $outer-spacing-xs;
  }
  &b-sm {
    padding-bottom: $outer-spacing-sm;
  }
  &b-md {
    padding-bottom: $outer-spacing-md;
  }
  &b-lg {
    padding-bottom: $outer-spacing-sm;
    @media #{$tablet-min} {
      padding-bottom: $outer-spacing-lg;
    }
  }
  &b-xl {
    padding-bottom: $outer-spacing-md;
    @media #{$tablet-min} {
      padding-bottom: $outer-spacing-xl;
    }
  }

  &t-xs {
    padding-top: $outer-spacing-xs;
  }
  &t-sm {
    padding-top: $outer-spacing-sm;
  }
  &t-md {
    padding-top: $outer-spacing-md;
  }
  &t-lg {
    padding-top: $outer-spacing-sm;
    @media #{$tablet-min} {
      padding-top: $outer-spacing-lg;
    }
  }
  &t-xl {
    padding-top: $outer-spacing-md;
    @media #{$tablet-min} {
      padding-top: $outer-spacing-xl;
    }
  }
}

// Tablet max no padding
.t-max-p0 {
  @media #{$tablet-max} {
    padding: 0;
  }
}

/***
 * Forms
 */

.form {
  // Form control
  &-control {
    font-size: $font-size-md;
    border: 1px solid $beeswax;
    border-radius: 0 0;

    &,
    &.form-select {
      height: 45px;
    }

    #{$state-focus} {
      box-shadow: $clear-shadow;
      border-color: $brandy;
    }
  }

  // Textarea
  &-textarea {
    resize: none;
  }
}

.resizable-textarea textarea {
  border-radius: 0 0;
}

/***
 * Buttons
 */

.btn {
  font-family: $font-itc-bold;
  font-size: $font-size-xs + 4;
  text-transform: uppercase;
  border: 0;
  border-radius: 0 0;
  padding: 10px 20px;
  letter-spacing: 1px;
  //margin-top: 15px;
  @include standard-transition();

  &.btn-warning,
  &.btn-primary {
    background-color: $barley-corn;
    #{$state-all} {
      background-color: $nepal;
    }
  }

  #{$state-all} {
    outline: none;
  }
}

/***
 * Icons
 */

.icon {
  font-size: 0;
  display: block;

  // See $icons-map in vars-icons
  &.icon {
    @each $label, $map in $icons-map {
      &-#{$label} {
        width: map-get($map, width);
        height: map-get($map, height);
        background-image: map-get($map, image);

        // Hover state.
        #{$state-hover} {
          background-image: map-get($map, hover);
        }

        // Active state.
        &.active {
          background-image: map-get($map, active);
        }

      }
    }
  }

  // Icon before
  &-before {
    #{$psuedo-before} {
      margin-right: 10px;
    }
  }
}

/***
 * Background
 */

.bg {
  &-divider {
    background: url($bg-divider-data) no-repeat center 0;
    padding-top: 40px;
  }

  &-dividerSh {
    padding-top: 40px;
    @include bg-divider-shorter;
    .d-title,
    h2 {
      font-size: $font-size-lg;
      margin-bottom: 30px;
      margin-top: 0;
    }
  }

  &-accent {
    background: url($bg-about-url) no-repeat 0 45px;
  }
}

/***
 * Fields
 */

.middle {
  &-block {
    @include middle-block;
  }

  &-content {
    @include middle-content;
  }
}

.gutter {
  &-row {

  }

  // Left gutter
  &-left {
    padding: 0;
    @media #{$normal-min} {
      left: 4%;
    }
  }

  // Right gutter
  &-right {
    @media #{$normal-min} {
      left: -4%;
      margin: 4% 0 0;
    }
    > div {
      padding: 30px;
      @include middle-content;

      @media #{$tablet-min} {
        padding: 65px;
      }
    }
    p {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.f {
  // Field with double border.
  &-border {
    padding: 5px;
    line-height: 1.8;
    border: 1px solid $nomad;

    > div {
      border: 1px solid $nomad;
    }

  }

  // Field with color white background.
  &-white > div {
    background-color: $white;
    height: 100%;
    padding: 30px;

    @media #{$tablet-min} {
      padding: 60px;
    }
  }

  // Field with color brownish background.
  &-dark > div {
    background-color: $parchment;
    padding: 20px;

    @media #{$tablet-min} {
      padding: 45px;
    }

    .form-control {
      border-color: $nomad;
    }

  }
}

/*
 * Venobox
 */
.venobox {
  overflow: hidden;
  position: relative;
  display: block;

  &::before {
  content: $ion-play;
  font-size: 4em;
  z-index: 2;
  color: rgba(255,255,255,1);
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all .15s ease 0s;
  -o-transition: all .15s ease 0s;
  transition: all .15s ease 0s;
  }

  &:hover {
    &::before {
      color: #fff;
      background-color: rgba(0,0,0,.5);
    }
    img {
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
    }
  }

  img {
    -webkit-transition: -webkit-transform .2s ease 0s;
    transition: -webkit-transform .2s ease 0s;
    -o-transition: transform .2s ease 0s;
    transition: transform .2s ease 0s;
    transition: transform .2s ease 0s,-webkit-transform .2s ease 0s;
  }
}

.vbox-container {
  overflow-y: unset;
}
.vbox-close {
  top: 0;
}
