/***
 * About / What is Go Lokal!
 */

.attachment {
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;

  &:nth-child(2n+2) .group-left {
    @media #{$tablet-min} {
      float: right;
    }
  }
}

.page-taxonomy-term-18 .view-about-term-content.view-display-id-parent > div {
  position: relative;
  #{$psuedo-before},
  #{$psuedo-after} {
    content: url($bg-about-url);
    display: block;
    position: absolute;
    z-index: -1;
  }
  #{$psuedo-before} {
    top: 8%;
  }
  #{$psuedo-after} {
    bottom: -50%;
    right: 0;
  }
}
