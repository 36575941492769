/***
 * Events
 */
.event {
  &-title {
    margin: 0;
  }
  &-image {
    @media #{$tablet-min} {
      margin-bottom: 0;
    }
  }

  // Upcoming events
  &-upcoming {
    border-bottom: 1px solid $swirl;
    padding: 20px 0;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;

    @media #{$tablet-min} {
      padding-bottom: 0;
    }

    // Event group
    .group {
      // Group left
      &-left {
        @media #{$tablet-min} {
          width: 12.2%;
          padding-left: 0;
        }
      }

      // Group right
      &-right {
        @media #{$tablet-min} {
          width: 87.8%;
          padding-left: 5px;
          text-align: left;
        }
      }
    }

    > div {
      left: 0;
      position: relative;
    }

    #{$state-hover} {
      background-color: $mongoose;
      > div {
        @media #{$tablet-min} {
          left: 20px;
        }
      }
      .pos-item {
        opacity: 1;
        right: 20px;
      }
    }
  }
  // End of upcoming events

  // RSS view mode
  &-rss {
    &:nth-child(n+2) {
      @media #{$tablet-max} {
        border-top: 1px solid $barley-corn;
        padding-top: 30px;
      }
    }

    .group-right {
      @media #{$tablet-min} {
        text-align: left;
      }
    }
  }
}
