/***
 * Flexslider
 */

.flexslider {
  margin: 0;
  border: 0;
  background-color: transparent;

  .flex-direction-nav {
    li {
      @include middle-block;
    }

    a {
      font-size: $font-size-slider-pager;
      color: $nepal;
      line-height: 20px;
      background-color: $botticelli;
      display: block;
      opacity: 1;
      width: auto;
      height: auto;
      position: relative;
      margin: 0;
      text-shadow: 0 0 transparent;
      padding: 20px;
      margin-top: -45px;
      z-index: 30;
      left: auto;
      right: auto;

      #{$state-hover} {
        color: $white;
        background-color: $straw;
      }

      #{$psuedo-before} {
        display: none;
      }
    }
  }

  #{$state-hover} {
    .flex-direction-nav .flex-prev,
    .flex-direction-nav .flex-next {
      left: auto;
      right: auto;
      opacity: 1;
    }
  }
}