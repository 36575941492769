/***
 * Fields
 */

.clearbox {
  clear: both;
}

hr {
  height: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  @include bg-divider-shorter;
}

.container .region .fullw {
  width: 100%;
  left: auto;
  border: 0;
  margin-top: 0;
  > div {
    padding: 0;
    border: 0;
    background-color: transparent;
  }
}

.label-inline {
  float: left;
}
.field {
  // Field image
  &-type-image {
    margin-bottom: 20px;
  }

  // Field body
  &-name-body {
    // Field body unordered list
    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        list-style: none;
        position: relative;
        padding-left: 22px;
        #{$psuedo-before} {
          font-family: $font-ionicons;
          content: $ion-record;
          color: lighten($nepal, 0.2);
          vertical-align: middle;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  // Field embedded map
  &-name-field-embedded-map iframe {
    max-height: 300px;
    overflow: hidden;
    @media #{$normal-min} {
      max-height: inherit;
    }
  }
}

.inline-items > div {
  display: inline-block;
  margin-left: -2px;
  margin-right: -2px;
  padding: 0 10px;
  vertical-align: top;
  &.radio {
    label {
      padding: 0;
    }
    .active {
      color: $nepal;
    }
    input {
      display: none;
    }
  }
  &.radio {
    margin: 0;
    line-height: 22px;
    &:nth-child(n+2) {
      border-left: 1px solid darken($thunder, 0.2);
    }
  }
}

.inline-item {
  display: inline-block;
  margin-left: -2px;
  margin-right: -2px;
  padding: 0 10px;
  vertical-align: top;
  &.col-item-4 {
    @media #{$tablet-min} {
      width: 25%;
    }
  }
  &.col-item-5 {
    @media #{$tablet-min} {
      width: 20%;
    }
  }
  &.middle {
    vertical-align: middle;
  }
}

.help-block {
  color: $mine-shaft;
}

/***
 * Location
 */

.street-address {
  float: left;
  margin-right: 5px;
}