/***
 * Views
 */

.view {
  /***
   * Views
   * Content
   */
  &-content + .text-center {
    clear: both;
  }

  /***
   * Views
   * Footer
   */
  &-footer {
    margin-top: 15px;
  }

  /***
   * Views
   * No results behavior
   */
  &-empty {
    text-align: center;
  }

  /***
   * Views
   * Pagination
   */
  .pagination > li > a, .pagination > li > span {
    border: 0;
    border-radius: 0 0;
    border-right: 1px solid $thunder;
    background-color: transparent;
    color: $thunder;
    padding: 0 10px;
    line-height: $text-normal;
  }

  /***
   * Views
   * Exposed form
   */
  &s-exposed-form {
    label {
      font-family: $font-itc-book;
      font-weight: $text-normal;
    }
    .views-exposed-widget {
      padding: 0 10px;
      @media #{$tablet-max} {
        width: 100%;
      }
      @media #{$tablet-min} {
        float: left;
        width: 50%;
      }
    }
    .views-widget-filter-field_business_name_value {
      width: 100%;
    }
    .views-widget {
      margin-top: 32px;
    }
    label + .views-widget {
      margin-top: 0;
    }
  }

  /***
   * Views
   * Exposed widget
   */
  &s-exposed-widgets {
    margin-bottom: 0;
  }
}

.more-link {
  padding-top: 20px;
}


