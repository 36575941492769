/***
 * Global
 */

html {
  position: relative;
  min-height: 100%;
}
body.html {
  font: #{$font-size-global}/1.8 $font-itc-book;
  color: darken($thunder, 0.2);
  background-color: $solitaire;
  // padding: 85px 0 247px;
  position: static;
  display: none;
  overflow-y: scroll;

  &.taxonomy-type-msme,
  &.node-type-msme-product {
    background-color: #FFF;
    font-family: $font-relative-12;

    @media #{$tablet-max} {
      padding-top: 0 !important;
    }

    .main-wrapper { overflow-x: hidden; }
    .footer {
      font-family: $font-itc-book;
    }
  }
}

strong, b {
  font-family: $font-itc-demi;

  .taxonomy-type-msme .body &,
  .node-type-msme-product .body &,
  .node-type-msme-product .info &  {
    font-family: $font-relative-bold;
  }
}
p + ul {
  margin-top: -10px;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
  @include css-property(backface-visibility, hidden, ('webkit'));
}

.alert {
  max-width: $container;
  margin: 20px auto;
}
.ionicon {
  font-size: $font-size-md;
  color: $white;
  text-align: center;
  background-color: $mine-shaft;
  width: 35px;
  height: 35px;
  float: left;
  overflow: hidden;
  padding: 15px;
  line-height: 42px;
  margin-right: 10px;
  @include css-property(border-radius, 40px, 'webkit');
}

/***
 * Links
 */

a {
  color: darken($thunder, 0.2);
  @include standard-transition();

  .taxonomy-type-msme &,
  .node-type-msme-product & {
    color: $brown-darker;
  }

  #{$state-hover} {
    color: $nepal;
    text-decoration: none;
  }

  #{$state-focus} {
    color: $nepal;
    outline: none;
    text-decoration: none;
  }
}

/***
 * Region
 */

.region {
  @include clearfix;
}

/***
 * Nodes
 */

.node-unpublished {
  background-color: transparent;
}

/***
 * Preloader
 */

.loader {
  @include background-color-transparent($black, 0.45);
  @include position(fixed, $top: 0, $left: 0, $z-index: 9999);
}

/*
 * Sticky Footer
 */
.main-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
}
.main-container-wrap {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

/*
 * Back to top
 */
#backtotop {
  border-radius: 0;
  height: 36px;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*
 * Alert
 */
.alert-block {
  border-radius: 0;
  border-bottom: 2px solid;
}