/***
 * Blocks
 */

.blt {
  // Block layout type a / 1
  &-a {
    background-color: $nomad;
    color: $white;
    padding: 35px 15px;
    overflow: hidden;
    a { color: $white; }
    .pos-item {
      opacity: 0;
    }
    .more-link {
      @media #{$tablet-min} {
        text-align: left;
      }
      .btn {
        background-color: $nomad;
        border: 1px solid $swirl;
        padding-left: 30px;
        padding-right: 30px;
        &:hover { background-color: $brandy; }
      }
    }
    .date-display-single {
      color: $swirl;
    }
    .block-title {
      @media #{$tablet-min} {
        text-align: left;
      }
    }
  }

  // Block layout type b / 2
  &-b {
    @media #{$tablet-min} {
      text-align: left;
    }
    .blt-title {
      @media #{$tablet-min} {
        float: left;
        width: 29%;
      }
      h2 {
        @media #{$tablet-min} {
          font-size: $font-size-xlg;
          padding-right: 30px;
          margin: 0;
        }
        span {
          margin-right: -5px;
          @media #{$tablet-min} {
            &:not(:last-child) {
              font-size: 25px;
            }
            &:last-child {
              display: block;
            }
          }
        }
      }
    }

    .blt-content {
      @media #{$tablet-min} {
        float: left;
        width: 71%;
      }
    }

    .blt-smaller {
      @media #{$normal-min} {
        width: 20%;
      }
    }
    .blt-larger {
      @media #{$normal-min} {
        width: 80%;
      }
    }
  }

  // Block layout type c / 3
  &-c {
    @media #{$tablet-min} {
      margin-top: -90px;
    }
  }

  &-line {
    .block-title {
      font-size: 34px;
      display: inline-block;
      background-color: $solitaire;
      padding-right: 10px;
      &:before {
        height: 5px;
        background: url($bg-divider-data) repeat-x 20% 0;
        @include position($top: 15px, $left: 0, $width: 100%, $z-index: -1);
      }
    }

    .title-wrapper {
      position: relative;
      overflow: hidden;
    }

    &:last-child {
      .block-title {
        padding-right: 0;
        padding-left: 25px;
        &:before {
          background-position: 70% 0;
        }
      }
      .title-wrapper {
        text-align: right;
      }
    }
  }
}