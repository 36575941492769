// Font family
$font-itc-book:                 "ITC Avant Garde Gothic Std Book";
$font-itc-bold:                 "ITC Avant Garde Gothic Std Bold";
$font-itc-light:                "ITC Avant Garde Gothic Std Extra Light";
$font-itc-demi:                 "ITC Avant Garde Gothic Std Demi";
$font-century-gothic:           "Century Gothic Regular";
$font-ionicons:                 "Ionicons";
$font-relative-bold:            "relative-bold";
$font-relative-10:              "relative-mono10pitch";
$font-relative-12:              "relative-mono12pitch";


// Font sizes
$font-size-xs:                  12px;
$font-size-sm:                  18px;
$font-size-md:                  20px;
$font-size-lg:                  30px;
$font-size-xlg:                 40px;
$font-size-xxlg:                70px;

$font-size-global:              16px;
$font-size-page-header:         50px;
$font-size-slider-pager:        50px;

$font-size-banner-h2:           44px;

$font-size-footer:              14px;

$font-size-block-title-front:   35px;
$font-size-block-title-footer:  14px;

$font-size-login-message:       22px;

$font-size-h2:                  25px;

$text-normal:                   normal;

/***
 * Embedded Fonts
 */

@font-face {
  font-family: $font-itc-book;
  font-style: $text-normal;
  font-weight: $text-normal;
  src: local($font-itc-book), url('../fonts/itc-avant-garde-gothic-std-book-58957161d80eb.woff') format('woff');
}

@font-face {
  font-family: $font-itc-demi;
  font-style: $text-normal;
  font-weight: $text-normal;
  src: local($font-itc-demi), url('../fonts/itc-avant-garde-gothic-std-demi-589572a199962.woff') format('woff');
}

@font-face {
  font-family: $font-itc-bold;
  font-style: $text-normal;
  font-weight: $text-normal;
  src: local($font-itc-bold), url('../fonts/itc-avant-garde-gothic-std-bold-589572c7e9955.woff') format('woff');
}

@font-face {
  font-family: $font-itc-light;
  font-style: $text-normal;
  font-weight: $text-normal;
  src: local($font-itc-light), url('../fonts/itc-avant-garde-gothic-std-extra-light-5895708744eb6.woff') format('woff');
}

@font-face {
  font-family: $font-century-gothic;
  font-style: $text-normal;
  font-weight: $text-normal;
  src: local($font-century-gothic), url('../fonts/century_gothic.ttf') format('truetype');
}

// relative
@font-face {
  font-family: $font-relative-10;
  font-style: $text-normal;
  font-weight: $text-normal;
  src: local($font-relative-10), url('../fonts/relative/Relative-10Pitch.ttf') format('truetype');
}
@font-face {
  font-family: $font-relative-12;
  font-style: $text-normal;
  font-weight: $text-normal;
  src: local($font-relative-12), url('../fonts/relative/Relative-12Pitch.ttf') format('truetype');
}
@font-face {
  font-family: $font-relative-bold;
  font-style: $text-normal;
  font-weight: $text-normal;
  src: local($font-relative-bold), url('../fonts/relative/Relative-Bold.ttf') format('truetype');
}
