// Import typography variables
@import 'vars-typography';

/***
 * Fonts
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: $text-normal;
}

h2 {
  font-size: $font-size-h2;
}

.block-title {
  margin-top: 0;
  margin-bottom: 20px;

  .front & {
    font-size: $font-size-block-title-front;
  }
}

.page-header {
  border-bottom: 0;
  @media #{$tablet-min} {
    font-size: $font-size-page-header;
  }
}

.field .field-label,
label {
  font-family: $font-itc-demi;
}

.text {
  // Sizes
  &-xs {
    font-size: $font-size-xs;
  }
  &-sm {
    font-size: $font-size-sm;
  }
  &-md {
    font-size: $font-size-md;
  }
  &-lg {
    font-size: $font-size-lg;
  }
  &-xlg {
    font-size: $font-size-xlg;
  }
  &-xxlg {
    font-size: $font-size-xxlg;
  }

  // Weights
  &-bold {
    font-family: $font-itc-bold;
  }

  // Trimmed text
  &-truncate {
    @include text-truncate;
  }
}

// ckeditor classes
.rtejustify {
  @media #{$tablet-max} {
    text-align: left;
  }
}

// Psuedo elements that uses ionicons
.venobox::before {
  display: inline-block;
  font-family: "Ionicons";
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
