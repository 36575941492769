/***
 * Directory
 */

.directory {
  &-list {
    // Directory header
    .view-header {
      display: none;
    }

    .col-xs-6 {
      @media #{$phone-landscape-max} {
        width: 100%;
      }
    }
  }

  // Directory item
  &-item {
    margin-bottom: 4.4px;
  }

  // Directory left
  &-left {
    @media #{$tablet-min} {
      float: left;
      padding-right: 20px;
      width: 34.5%
    }
  }

  // Directory right
  &-right {
    @media #{$tablet-min} {
      float: left;
      width: 65.5%;
    }
  }
}