/***
 * Navigation
 */

.menu > li > a,
.menu > li > a {
  #{$state-hover},
  #{$state-focus} {
    background-color: transparent;
  }
}
.caret {
  margin-top: -2px;
  border-width: 5px;
}

.navbar {
  &-default .navbar-right {
    margin-right: 0;

    @media #{$tablet-min} {
      padding: 30px $standard-gutter;

      .taxonomy-type-msme:not(.page-srcl) &,
      .node-type-msme-product & {
        padding-top: 42px;
      }
    }

    .menu {
      float: none;

      .not-front & {
        text-align: right;
      }

      > li {
        font-size: $font-size-global;
        padding: 5px;
        float: none;
        text-transform: uppercase;
        @include middle-block;

        @media #{$tablet-min} {
          font-size: $font-size-global - 3;
          padding: 0 7px;
        }

        @media #{$normal-min} {
          font-size: $font-size-global - 1;
          padding: 0 6px;
        }

        @media #{$wide-min} {
          font-size: $font-size-global;
          padding: 0 12px;

          .not-front & {
            font-size: $font-size-footer;
          }
        }

        a,
        .nolink {
          font-family: $font-century-gothic;
          color: $thunder;
          background-color: transparent;
          padding: 0;

          .taxonomy-type-msme &,
          .node-type-msme-product & {
            font-size: $font-size-xs;
          }

          #{$state-hover},
          #{$state-focus} {
            color: $barley-corn;
            background-color: transparent;
          }

          &.active {
            color: $nepal;
            #{$state-hover},
            #{$state-focus} {
              color: $barley-corn;
            }
          }
        }

        ul {
          font-size: $font-size-global;
          background-color: $white;
          display: none;
          top: 100%;
          padding: 20px;
          min-width: 180px;
          border-radius: 0 0;
          border: 0;
          right: auto;
          // left: 0;
          // top: 25px;
          text-align: left;
          @include css-property(box-shadow, 0 5px 8px rgba(0, 0, 0, 0.2), 'webkit');
          li {
            &:nth-child(n+2) {
              margin-top: 10px;
              border-top: 1px dashed $nomad;
              padding-top: 10px;
            }
            a {
              text-transform: none;
            }
          }
        }

        #{$state-hover}:hover ul {
          display: block;
        }
      }
    }
  }

  /***
   * Responsive
   * Menu
   */
  .dl-menuwrapper {
    margin-right: 0;
    text-align: right;
    max-width: 100%;
    margin: 18px 0 15px;
    perspective: none;

    @media #{$tablet-max} {
      .page-srcl.taxonomy-type-msme & {
        margin-top: 0;
      }
      .taxonomy-type-msme &,
      .node-type-msme-product & {
        margin-top: 45px;
        margin-bottom: 10px;
      }
    }

    .front & {
      margin-top: 10px;
    }

    button {
      &,
      #{$state-hover} {
        background: $barley-corn;
      }
    }

    ul {
      background: $barley-corn;
    }

    li {
      text-align: left;
      .active-trail,
      .active {
        background: darken($barley-corn, 10%);
      }
    }

    .dl-back + li {
      visibility: hidden;
      position: absolute;
      z-index: -1;
    }

    .dl-menu {
      left: 0;
    }

    .caret {
      display: none;
    }
  }

}
