/***
 * Supplier
 */

.page-node-8 .region-content > .block-nodeblock:first-child {
  margin-top: -45px;
  margin-bottom: 20px;
}

/***
 * Supplier
 * Product photos
 */

.product-image {
  margin: 0 -5px 5px;

  @media #{$normal-min} {
    max-width: 1062px;
    margin-left: auto;
    margin-right: auto;
  }

  > div {
    padding: 0 5px;
    @media #{$phone-landscape-max} {
      width: 100%;
    }
  }
}

/***
 * Supplier
 * Online outlets
 */

.online-outlets > a {
  margin: 0 20px 20px;
  @include middle-block();
}