.term-block {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .top {
    .icon {
      font-size: $font-size-xlg;
      display: inline;
      line-height: 1;
    }

    @media #{$normal-min} {
      padding: 15px 85px;
      padding-bottom: 8%;

      .mb-xl { margin-bottom: 10%; }
    }
    @media #{$wide-max} {
      padding-left: 8%;
      padding-right: 8%;
      .mb-lg {
        margin-bottom: 30px;
      }
    }
    @media #{$normal-max} {
      width: 100%;
      padding: 3% 5%;
      .mb-xl { margin-bottom: 35px; }
    }
    @media #{$tablet-max} {
      padding: 15px;
    }
    @media #{$wide-min} {

    }
  }
  .back-msme {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .icon {
      margin-right: 15px;
    }
  }
  .see-prods .icon {
    position: absolute;
    top: -12px;
  }
  .h1 {
    @media #{$normal-min} {
      font-size: $outer-spacing-lg;
    }
  }
  .see-prods {
    font-size: $font-size-sm;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
  .body {
    font-size: $font-size-sm;
    line-height: 1.5;
    z-index: 2;

    &::after {
      content: "";
      width: 36%;
      height: 31%;
      background-image: url($bg-pattern-flesh);
      background-position: right;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;

      @media #{$normal-max} {
        width: 50%;
      }
      @media #{$wide-max} {
        background-size: 35px;
        background-position: -12px -6px;
      }
    }

    @media #{$normal-min} {
      padding: 15px 85px;
      padding-right: 35%;
      height: 63%;
    }
    @media #{$wide-max} {
      padding-left: 8%;
      padding-right: 8%;
    }
    @media #{$normal-max} {
      margin-top: 10%;
      margin-bottom: 10%;
      font-size: 16px;
    }
    @media #{$tablet-max} {
      padding: 15px;
    }
  }
  @media #{$tablet-max} {
    > div.col-xs-6 {
      width: 100%;

      .body {
        margin: 0;
        padding: 10% 5%;
        min-height: 400px;
      }
      .a-items-center {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }
  }
}
.product-item {
  padding: 0 1px;
  margin-bottom: 75px;
  img { width: 100%; }
  .node-msme-product,
  .prod-title {
    font-size: 14px;
  }
  .field-name-field-category {
    padding-right: 25px;
  }
  .field-name-field-msme-product-image > a {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    &::after {
      @media #{$normal-min} {
        opacity: 0;
      }
      content: "\f499";
      color: #FFF;
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px 10px;
      font-size: 18px;
      text-align: center;
      background-color: #c9b092;
      display: inline-block;
      font-family: "Ionicons";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-rendering: auto;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover::after {
      opacity: 1;
    }
  }
  @media #{$tablet-max} {
    margin-bottom: 50px;
  }
}

/*
 * Product Page
 */
.prod-title > .container a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  .icon {
    display: inline;
    font-size: 26px;
    line-height: 1;
    margin-right: 10px;
  }
}
.prod-title .h1 {

  @media #{$wide-min} {
    font-size: $font-size-slider-pager;
  }
  @media #{$normal-min} {
    max-width: 50%;
  }
}
.prod-details {
  position: relative;
  .d-flex { z-index: 2; }
  &::after {
    content: "";
    width: 340px;
    height: 300px;
    background-image: url($bg-pattern-flesh);
    background-position: right;
    position: absolute;
    bottom: 0;
    right: 0;

    @media #{$wide-max} {
      background-size: 35px;
      background-position: -15px -17px;
      width: 29%;
      height: 55%;
    }
    @media #{$tablet-max} {
      width: 45%;
      height: 17%;
    }
  }
  > div:nth-child(2) {
    @media #{$wide-min} {
      padding: 25px 85px;
      padding-right: 20%;
    }
    @media #{$wide-max} {
      padding: 3.5%;
      padding: 3%;
      width: 60%;
    }
    @media #{$tablet-max} {
      min-height: 400px;
      padding: 6% 4%;
    }
  }
  > div:nth-child(1) {
    @media #{$wide-max} {
      width: 40%;
    }
  }
  > div {
    @media #{$tablet-max} {
      width: 100% !important;

      &.j-content-center {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .category {
    font-size: $font-size-md;
    line-height: 1;

    @media #{$normal-max} {
      font-size: $font-size-sm;
    }
  }
  .body, .info {
    font-size: $font-size-md - 2;
    z-index: 2;
    line-height: 1.5;

    @media #{$normal-max} {
      font-size: $font-size-global;
    }
  }
}
