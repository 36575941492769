/***
 * Tabs
 */

.nav-tabs {
  border-color: $nomad;
  margin: 0 $standard-gutter 30px;
  > li > a:hover,
  > li.active > a,
  > li.active > a:hover,
  > li.active > a:focus {
    border-color: $nomad;
  }
}